// TODO: move these to an app config and ADO library

const urls = {
  DEV: 'https://fa-devqa-dev-microservices-centralus.azurewebsites.net/api/CaPrivacy/PrivacyDataRequest?code=-eh-2O1VYv7X87sLsoZavJ-z5jZW8A7eROcvfD_Qjn6sAzFuk39e0g==&clientId=aurora',
  UAT: 'https://fa-devqa-uat-microservices-centralus.azurewebsites.net/api/CaPrivacy/PrivacyDataRequest?code=TOFaNqzZNKFuOR8tiIabAr9L/jAXsfPHJHvm3a9nYViC7Qvs5arahA==&clientId=aurora',
  PROD: 'https://fa-prod-prod-microservices-centralus.azurewebsites.net/api/CaPrivacy/PrivacyDataRequest?code=2sS04AW7SnxTM2dFwmKsE4XDIAcu8O9U/087wlyLIHTHk//XlIAFHQ==&clientId=aurora',
};

const getPostUrl = () => {
  if (import.meta.env.VITE_POST_URL) {
    return import.meta.env.VITE_POST_URL;
  }

  const queryParams = new URLSearchParams(window.location.search);
  // '!=' retained here to preserve existing business logic
  // this can be refactored in a future change
  const useProd = queryParams.get('useProd') != null;
  const isUat = window.location.hostname.match(/privacy-uat/) !== null;
  const isDev = window.location.hostname.match(/privacy-dev/) !== null;

  if (!useProd) {
    if (isUat) return urls.UAT;
    if (isDev) return urls.DEV;
  }

  return urls.PROD;
};

const recaptchaSiteKey =
  import.meta.env.VITE_RECAPTCHA_SITE_KEY || '6LfHRyooAAAAAA1wAA0t-fOfG5GVg8l_l7zftUoE';

export { getPostUrl, recaptchaSiteKey };
