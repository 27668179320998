import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { getPostUrl, recaptchaSiteKey } from '../../lib/utils';
import { rights } from '../../constants/rights';
import { Button, CheckboxLabel, FieldError, TextInput, PhoneInput } from '../../ui';
import * as yup from 'yup';

const submissionErrorMessage = 'Error occurred submitting form. Please reload and try again.';

const createSchema = (withRecaptcha) => {
  const shape = {
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    emailAddress: yup.string().required('Email Address is required').email('Invalid email format'),
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number is not valid')
      .required('Phone Number is required'),
    address: yup.string().required('Street Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State/Province is required'),
    zipCode: yup.string().required('Zip Code is required'),
    description: yup.string().required('Description of Request is required'),
    declareInformation: yup.boolean().oneOf([true], 'You must declare the information'),
  };

  if (withRecaptcha) {
    shape.recaptcha = yup.string().required('Recaptcha required.');
  }

  return yup.object().shape(shape);
};

const createInitialValues = (isInternal, withRecaptcha) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    makingRequestOnBehalf: false,
    makingRequestAsAuthorizedAgent: false,
    rights: rights.reduce((acc, cur) => {
      acc[cur.name] = false;
      return acc;
    }, {}),
    description: '',
    declareInformation: false,
    isInternal,
  };

  if (withRecaptcha) {
    initialValues.recaptcha = '';
  }

  return initialValues;
};

const CustomerDetails = ({ onComplete, isInternal, withRecaptcha }) => {
  const [disableForm, setDisableForm] = useState(false);
  const [warning, setWarning] = useState('');

  const handleSubmit = async (values) => {
    setWarning('');
    setDisableForm(true);

    const data = { ...values };
    delete data.recaptcha; // We may want to include this to verify recaptcha server side

    try {
      const postUrl = getPostUrl();
      const postResult = await axios.post(postUrl, data);

      if (postResult.status >= 200 && postResult.status <= 204) {
        onComplete();
      } else {
        setWarning(submissionErrorMessage);
      }
    } catch (error) {
      setWarning(submissionErrorMessage);
    } finally {
      setDisableForm(false);
    }
  };

  return (
    <Formik
      initialValues={createInitialValues(isInternal, withRecaptcha)}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={createSchema(withRecaptcha)}
      onSubmit={handleSubmit}
    >
      {({ values, errors, setFieldValue }) => (
        <Form autoComplete="off" className="mt-12">
          <div className="max-w-2xl mx-auto p-6 bg-white rounded shadow">
            <div className="text-3xl font-medium self-center mb-8 mt-6">Private Data Request</div>
            {/*Customer Data Section*/}
            <TextInput fieldName="firstName" label="First Name" />
            <TextInput fieldName="lastName" label="Last Name" />
            <TextInput fieldName="emailAddress" label="Email Address" />
            <PhoneInput fieldName="phoneNumber" label="Phone Number" />
            <TextInput fieldName="address" label="Street Address" />
            <TextInput fieldName="city" label="City" />
            <TextInput fieldName="state" label="State/Province" />
            <TextInput fieldName="zipCode" label="ZipCode" />
            <TextInput
              fieldName="description"
              label="Description of Request"
              placeholder="Enter Description of Request"
              type="textarea"
            />
            {/*Request Behalf Section*/}
            <div className="mb-4">
              <div>
                <Field
                  type="checkbox"
                  name="makingRequestOnBehalf"
                  id="makingRequestOnBehalf"
                  className="mr-2"
                  onChange={() => {
                    setFieldValue('makingRequestOnBehalf', !values.makingRequestOnBehalf);
                    setFieldValue('makingRequestAsAuthorizedAgent', false);
                  }}
                />
                <CheckboxLabel htmlFor="makingRequestOnBehalf">
                  Making Request on My Own Behalf
                </CheckboxLabel>
              </div>
              <div>
                <Field
                  type="checkbox"
                  name="makingRequestAsAuthorizedAgent"
                  id="makingRequestAsAuthorizedAgent"
                  className="mr-2"
                  onChange={() => {
                    setFieldValue('makingRequestOnBehalf', false);
                    setFieldValue(
                      'makingRequestAsAuthorizedAgent',
                      !values.makingRequestAsAuthorizedAgent,
                    );
                  }}
                />
                <CheckboxLabel htmlFor="makingRequestAsAuthorizedAgent">
                  Making Request on Another's Behalf As Authorized Agent
                </CheckboxLabel>
              </div>
            </div>
            {/*Rights Selection Section*/}
            <div className="flex flex-row items-center mb-4">
              <div>
                <p className="font-medium text-gray-900">
                  Rights<span className="text-red-500 font-bold"> *</span>
                </p>
                <div className="ml-8">
                  {rights.map((r) => (
                    <div key={r.name}>
                      <Field
                        type="checkbox"
                        name={`rights.${r.name}`}
                        id={r.name}
                        className="mr-2"
                      />
                      <CheckboxLabel htmlFor={r.name}>{r.label}</CheckboxLabel>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/*Declaration Section*/}
            <div className="mb-4">
              <Field
                type="checkbox"
                name="declareInformation"
                id="declareInformation"
                className="mr-2"
              />
              <CheckboxLabel htmlFor="declareInformation">
                I Declare that the information given by me is correct to the best of my knowledge,
                and that I am entitled to make the request identified above under the laws
                applicable to me
              </CheckboxLabel>
              <FieldError fieldName="declareInformation" />
            </div>
            {/*Errors and Submission Section*/}
            {warning && (
              <div className="flex justify-center">
                <p className="text-red-600 font-bold">{warning}</p>
              </div>
            )}
            {Object.keys(errors).length > 0 ? (
              <div className="flex justify-center">
                <p className="text-red-600 font-bold">
                  REQUIRED FIELDS MUST BE COMPLETED TO CONTINUE.
                </p>
              </div>
            ) : null}
            <div className="flex justify-center">
              <Button disabled={disableForm}>Rights Request Submission</Button>
            </div>
            {/*Recaptcha Section*/}
            {withRecaptcha && (
              <>
                <div className="flex justify-center mt-5">
                  <ReCAPTCHA
                    sitekey={recaptchaSiteKey}
                    onChange={(value) => setFieldValue('recaptcha', value ? value : '')}
                  />
                </div>
                <div className="flex justify-center">
                  <FieldError fieldName="recaptcha" />
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { CustomerDetails };
